import { makeVar, useQuery } from "@apollo/client";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { generate } from "shortid";
import {
    Image,
    ContentBlockData,
    getSlug,
    GET_MEMA_PAGE,
    GET_PAGE,
    PageTitle,
    RelatedPage,
    showLoad,
} from "@plinknz/tah-website-elements";
import { Hero } from "../components/hero";
import { Menu } from "../components/menu";
import * as ROUTES from "../config/router";
import { removeLeadingSlash } from "../utility/remove-leading-slash";
import { NotFound } from "./404";
import { CustomerFormBlock } from "../components/content-blocks/forms/customer-form-block";
import { CallToActionBlock } from "../components/content-blocks/call-to-action-block";
import { Content } from "../components/content-blocks/content";
import { DownloadBlock } from "../components/content-blocks/download-block";
import { GalleryBlock } from "../components/content-blocks/gallery";
import { GoogleCalendar } from "../components/content-blocks/google-calendar";
import { LinksBlock } from "../components/content-blocks/links-block";
import { NewsGridBlock } from "../components/content-blocks/news-grid-block";
import { PictureList } from "../components/content-blocks/picture-list";
import { RegistrationForm } from "../components/content-blocks/forms/registration-form";
import { Waiata } from "../components/content-blocks/waiata";
import { YoutubePlayerContentBlock } from "../components/content-blocks/youtube-player";
import { UserContext } from "../service/reducers/user";


const NEWS_GRID_ITEMS = 6;
export const newsGridLimit = makeVar(NEWS_GRID_ITEMS);

export const PAGE_SUFFIX = "Whakarewa";

export interface PageData {
    id: number;
    title: string;
    subtitle?: string;
    slug?: "title";
    cover_image?: Image;
    thumbnail_image?: null;
    show_children_pages: boolean | null;
    children: RelatedPage[];
    parent: RelatedPage;
    content: ContentBlockData[];
}

export interface PageQueryData {
    pages: PageData[];
}

export interface MemberPageQueryData {
    memberOnlyPages: PageData[];
}

export interface PageProps {
    suffix?:       string;
    slugOverride?: string;
    memberPages?:  boolean;
}

export const Page = ({
    suffix = PAGE_SUFFIX,
    slugOverride,
    memberPages = false,
}: PageProps) => {
    const location = useLocation();
    const slug = slugOverride || getSlug(location);
    const variables = { slug, limit: newsGridLimit() };
    const { dispatch } = React.useContext(UserContext);
    const history = useHistory();
    const calculatedSuffix = slug !== removeLeadingSlash(ROUTES.home()) ? suffix : "";

    const { loading, data, error, refetch } =
        memberPages ?
            useQuery<MemberPageQueryData>(
                GET_MEMA_PAGE,
                { variables }
            ) :
            useQuery<PageQueryData>(
                GET_PAGE,
                { variables }
            );

    newsGridLimit.onNextChange(async (limit) => refetch({ limit, slug }));

    if (!data && showLoad(loading)) {
        return (
            <Hero
                title=""
                subtitle=""
                hasChildPages={false}
                isLoading={loading}
            />
        );
    }
    
    const pages = memberPages ?
        (data as MemberPageQueryData)?.memberOnlyPages :
        (data as PageQueryData)?.pages;

    if (!showLoad(loading) && !error && pages?.length === 0) {
        return <NotFound />;
    }

    if (error) {
        if (error.toString().match("Invalid token")) {
            console.warn("Resetting stale token");
            dispatch({ type: "reset" });
            history.push(ROUTES.home());
        }
        console.warn(error);

        return (
            <Hero
                title="There was an unexpected error"
                subtitle={error?.networkError?.message}
            />
        );
    }

    if (!pages || pages.length < 1) {
        return <NotFound />;
    }

    const page = pages[0];

    const showChildPages = page?.show_children_pages;

    return (
        <>
            <Hero
                title={page.title}
                subtitle={page.subtitle}
                image={page.cover_image}
                hasChildPages={showChildPages && page.children.length > 0}
                isLoading={loading}
            />
            <PageTitle
                title={page.title}
                suffix={calculatedSuffix}
            />
            <Menu
                links={page.children}
                parent={page.parent}
                currentPage={page}
                show={!!showChildPages}
            />
            <section>
                {page.content.map((block) => {
                    const key = generate();
                    // eslint-disable-next-line no-underscore-dangle
                    switch (block.__typename) {
                        case "ComponentContentBlocksCustomerForm":
                            return <CustomerFormBlock key={key} data={block} />;
                        case "ComponentContentBlocksCallToAction":
                            return <CallToActionBlock key={key} data={block} />;
                        case "ComponentContentBlocksContent":
                            return <Content key={key} data={block} />;
                        case "ComponentContentBlocksDownloadFiles":
                            return <DownloadBlock key={key} data={block} />;
                        case "ComponentContentBlocksImageGallery":
                            return <GalleryBlock key={key} data={block} />;
                        case "ComponentContentBlocksGoogleCalendar":
                            return <GoogleCalendar key={key} data={block} />;
                        case "ComponentContentBlocksLinks":
                            return <LinksBlock key={key} data={block} />;
                        case "ComponentMemberPageGrid":
                        case "ComponentContentBlocksPanuiGrid":
                        case "ComponentContentBlocksNewsGrid":
                            return <NewsGridBlock key={key} data={block} />;
                        case "ComponentContentBlocksProfileSet":
                            return <PictureList key={key} data={block} />;
                        case "ComponentContentBlocksRegistrationForm":
                            return <RegistrationForm key={key} data={block} />;
                        case "ComponentContentBlocksWaiata":
                            return <Waiata key={key} data={block} />;

                        case "ComponentContentBlocksYoutubePlayer":
                            return (
                                <YoutubePlayerContentBlock
                                    key={key}
                                    data={block}
                                />
                            );
                        default:
                            return null;
                    }
                })}
            </section>
        </>
    );
};
