/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-lines */
import { paramCase } from "change-case";
import * as React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { classNames } from "@plinknz/tah-website-elements";
import { BlockQuote } from "../components/blockquote";
import { Button } from "../components/button";
import { Hero } from "../components/hero";
import { Loader } from "../components/loader";
import { CallToActionBlock } from "../components/content-blocks/call-to-action-block";
import { DownloadBlock } from "../components/content-blocks/download-block";
import { GalleryBlock } from "../components/content-blocks/gallery";
import { GoogleCalendar } from "../components/content-blocks/google-calendar";
import { LinksBlock } from "../components/content-blocks/links-block";
import { NewsGridBlock } from "../components/content-blocks/news-grid-block";
import { YoutubePlayerContentBlock } from "../components/content-blocks/youtube-player";
import { AddressFinder } from "../components/form/address-finder";
import { Checkbox } from "../components/form/checkbox";
import { DatePicker } from "../components/form/datepicker";
import { Form } from "../components/form/form";
import { SignatureCanvas } from "../components/form/signature-canvas";
import { Radio, RadioColumn } from "../components/form/radio";
import { Dropdown } from "../components/form/dropdown";
import { Whakapapa } from "../components/form/whakapapa/whakapapa";
import { Field } from "../components/form/field";

interface StyleGuideSectionProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
    subtitle?: string;
    contain?: boolean;
}

const StyleGuideSection = ({
    title,
    subtitle,
    children,
    contain = false,
    className,
    ...props
}: StyleGuideSectionProps) => (
    <section
        id={paramCase(title)}
        className={classNames(
            "style-guide-section",
            { "constrain-width": contain },
            className
        )}
        {...props}>
        <div
            className={`style-guide-section-heading ${
                !contain ? "constrain-width" : ""
            }`}>
            <h1 className="heading-2 primary-color rotate">
                {title}{" "}
                {subtitle && (
                    <small className="style-guide-section-subtitle">
                        | {subtitle}
                    </small>
                )}
            </h1>
            <hr className="style-guide-section-break" />
        </div>
        <div className="style-guide-section-content">{children}</div>
    </section>
);

interface SwatchProps {
    title: string;
    value: string;
    outline?: boolean;
}
const Swatch = ({ title, value, outline = false }: SwatchProps) => (
    <div className={`swatch small ${outline ? "outline" : ""} colour-${value}`}>
        <div className="swatch-inner">
            <p className="swatch-title">{title}</p>
            <div role="group" className="swatch-variations">
                <span className="swatch-variation tint-1" />
                <span className="swatch-variation shade-1" />
            </div>
        </div>
    </div>
);

export const StyleGuide = () => {
    return (
        <div className="style-guide">
            <Helmet>
                <title>Style Guide - Whakarewa</title>
            </Helmet>

            <Hero
                title="Ārahi Huatau / Style Guide"
                subtitle="A working site web design style guide for Whakarewa."
            />

            <StyleGuideSection title="Tae" subtitle="Colours" contain>
                <div className="style-guide-swatches" role="group">
                    <Swatch title="Primary - Whakarewa Blue" value="primary" />
                    <Swatch title="Secondary - Beach" value="secondary" outline />
                    <Swatch title="Tertiary - Pounamu" value="moss" />
                    <Swatch title="Body" value="body" />
                    <Swatch title="Off Black" value="dark" />
                    <Swatch title="Light" value="light" outline />
                
                </div>
            </StyleGuideSection>

            <StyleGuideSection title="Momotuhi" subtitle="Type" contain>
                <h1>Header 1</h1>
                <h2>Header 2</h2>
                <h3>Header 3</h3>
                <h4>Header 4</h4>
                <h5>Header 5</h5>
                <h6>Header 6</h6>

                <p>
                    Matua Hautere te tipuna i hoea mai rā. I Hawaiki-nui, i
                    Hawaiki-roa, Hawaiki-pāmamao e. Te ara whakaheke i tōna
                    tipuna a Kupe. He tipua, he tangata Matua Hautere e.
                </p>
                <p>
                    E te whakatau nāu te pōhiri Ki Te Hoiere o Matua Hautere
                    Kaikaiāwaro nāu i arataki - ki uta! Tūtūmāpou e tū mai rā ki
                    runga Koia te iwi Pakohe Whakarewa E te whakatau nāu te
                    pōhiri Ki Te Hoiere o Matua Hautere Kaikaiāwaro nāu i
                    arataki - ki uta! Tūtūmāpou e tū mai rā ki runga Koia te iwi
                    Pakohe Whakarewa
                </p>
                <p>
                    Ekore <em>e tika</em> kia noho he Maori rawakore ki tenei
                    whenua; ehara tenei i te mea e ora ai tatou e tika ai ranei
                    ratou. E mea ana tatou kia noho te Maori i runga i te
                    rangimarie penei me <strong>tatou, a e ki ana</strong> ahau
                    mehemea tera tetahi iwi i whiwhi nui ki
                </p>
            </StyleGuideSection>

            <StyleGuideSection title="Rārangi" subtitle="Lists" contain>
                <h4>Unordered</h4>
                <ul>
                    <div>
                        <li>
                            <div className="style-guide-section-list">
                                Ki te whenua{" "}
                                <strong>
                                    kua kite ratou kei to mawehe atu{" "}
                                </strong>{" "}
                                o ratou whenua i a ratou, e tika ana ano kia.
                            </div>
                        </li>
                        <li>
                            <div className="style-guide-section-list">
                                Kupu i reira ko te whenua{" "}
                                <em>ki te Kai Tuhi </em> facilisis at iwi ki a
                                ratou. He nui rapea nga korero mo nga rori, mo
                                nga.
                            </div>
                        </li>
                        <li>
                            <div className="style-guide-section-list">
                                Ko te utu mo te Waka, Maori i te tau ka te 10s.,
                                he mea utu ki mua. Ka tukuna atu i te meera.
                            </div>
                        </li>
                        <li>
                            <div className="style-guide-section-list">
                                Me ka tukua mai e ia aua moni ki te Kai Tuhi ki
                                Poneke nei. Ta ratou kupu i reira ko te whenua
                                kia whakawakia kia{" "}
                                <a href="#">kia watea ratou i nga raruraru</a>{" "}
                                whakawakia Kua tonoa te Paremete e te Kawana kia
                                hui mai i te 2 o nga haora o te awatea o te
                                Taitei.
                            </div>
                        </li>
                    </div>
                </ul>
                <h4>Ordered</h4>
                <ol className="style-guide-section-list">
                    <li>
                        Kia whakawakia kia{" "}
                        <strong>E ngari me tino mohio ano te Muera</strong> ki
                        te tangata kei to mawehe atu.
                    </li>
                    <li>
                        Kei to mawehe atu <em>nga tikanga pera</em> ki te
                        tangata Ka tukuna atu i te meera ki te tangata e hiahia
                        ana hiahia.
                    </li>
                    <li>
                        Iwi ki a ratou. He nui rapea nga korero mo nga rori ki
                        te tangata e hiahia ana.
                    </li>
                    <li>
                        Iwi ki a ratou. He nui rapea nga korero mo nga rori, mo
                        nga ruritanga hoki, mo te aha atu; otira ko te tino kupu
                        tena, <a href="#">Ta ratou kupu i reira ko te whenua</a>{" "}
                        korero iwi ki a ratou. He nui rapea nga korero mo nga
                        rori ki te mahi i nga mahi o te motu.
                    </li>
                </ol>
            </StyleGuideSection>

            <StyleGuideSection
                title="Pātene"
                subtitle="Buttons"
                contain
                className="style-guide-buttons">
                <Button sizeStyle="large">
                    Generic Button
                    <Button.Icon className="fas fa-arrow-right" />
                </Button>
                <Button>Button</Button>
                <Button sizeStyle="small">Button</Button>
                <br />
                <br />

                <Button variant="primary" sizeStyle="large">
                    Primary Button
                </Button>
                <Button variant="primary">
                    Primary Button{" "}
                    <Button.Icon className="fas fa-arrow-right" />
                </Button>
                <Button variant="primary">Primary Button</Button>
                <Button variant="primary" sizeStyle="small">
                    Primary Button
                </Button>
                <br />
                <br />

                <Button variant="secondary" sizeStyle="large">
                    Secondary Button
                </Button>
                <Button variant="secondary">Secondary Button</Button>
                <Button variant="secondary" sizeStyle="small">
                    Secondary Button{" "}
                    <Button.Icon className="fas fa-arrow-right" />
                </Button>
                <br />
                <br />

                <Button variant="tertiary" sizeStyle="large">
                    Tertiary Button{" "}
                    <Button.Icon className="fas fa-arrow-right" />
                </Button>
                <Button variant="tertiary">Tertiary Button</Button>
                <Button variant="tertiary" sizeStyle="small">
                    Tertiary Button
                </Button>

                <br />
                <br />
                <Button disabled>Disabled Button</Button>
                <Button disabled>Disabled Button</Button>
            </StyleGuideSection>

            <StyleGuideSection title="Tohutoro" subtitle="Blockquote" contain>
                <BlockQuote
                    text="
                    Matua Hautere te tipuna i hoea mai rā. 
                    I Hawaiki-nui, i Hawaiki-roa, Hawaiki-pāmamao e. 
                    Te ara whakaheke i tōna tipuna a Kupe. 
                    He tipua, he tangata Matua Hautere e."
                />
                <div className="content" />
            </StyleGuideSection>

            <StyleGuideSection title="Whakauta" subtitle="Loading" contain>
                <div className="style-guide-loaders">
                    <div>
                        <Loader size="large" />
                        <p>Large</p>
                    </div>
                    <div>
                        <Loader />
                        <p>Normal</p>
                    </div>
                    <div>
                        <Loader size="small" />
                        <p>Small</p>
                    </div>
                </div>
            </StyleGuideSection>

            <StyleGuideSection title="Tikiake" subtitle="One no image">
                <DownloadBlock
                    data={{
                        __typename: "ComponentContentBlocksDownloadFiles",
                        id: "1",
                        heading: "Whakarewa Annual Reports",
                        description:
                            "Whakarewa annual report for the financial year 2020-2021",
                        files: [
                            {
                                id: "1",
                                name: "Annual Report 2021",
                                file: {
                                    id: "1",
                                    name: "NRAIT Annual Report",
                                    url: "https://images.whakarewa.nz/58_NRAIT_annual_report_2021_9002d75809_746fdc0f70.pdf",
                                    size: 2680900.21,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="Tikiake" subtitle="One with image">
                <DownloadBlock
                    data={{
                        __typename: "ComponentContentBlocksDownloadFiles",
                        id: "1",
                        heading: "Annual Report 2021",
                        description: "",
                        files: [
                            {
                                id: "1",
                                name: "Annual Report 2020",
                                image: { url: "/images/our-place-te-uma.png" },
                                file: {
                                    id: "1",
                                    name: "NRAIT Annual Report",
                                    url: "https://images.whakarewa.nz/58_NRAIT_annual_report_2021_9002d75809_746fdc0f70.pdf",
                                    size: 2680900.21,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="Tikiake" subtitle="One with image and description">
                <DownloadBlock
                    data={{
                        __typename: "ComponentContentBlocksDownloadFiles",
                        id: "1",
                        heading: "Annual Report 2020",
                        description: "Rēhita mai",
                        files: [
                            {
                                id: "1",
                                name: "Register with Whakarewa",
                                image: { url: "/images/paddles.jpeg" },
                                file: {
                                    id: "1",
                                    name: "Chorus Fibre Consent Form",
                                    url: "https://images.whakarewa.nz/58_NRAIT_annual_report_2021_9002d75809_746fdc0f70.pdf",
                                    size: 2680900.21,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection
                title="Tikiake"
                subtitle="One with image and description">
                <DownloadBlock
                    data={{
                        __typename: "ComponentContentBlocksDownloadFiles",
                        id: "1",
                        heading: "Ammendments to Whakarewa Deed",
                        description:
                            "Whakarewa annual report for the financial year 2020-2021",
                        files: [
                            {
                                id: "1",
                                name: "Ammendments to Whakarewa Deed",
                                image: { url: "/images/art-craft.jpeg" },
                                file: {
                                    id: "1",
                                    name: "Chorus Fibre Consent Form",
                                    url: "http://eepurl.com/hI9AK1",
                                    size: 2680900.21,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection
                title="2 Tikiake"
                subtitle="Two, one has an Image, one using default image">
                <DownloadBlock
                    data={{
                        __typename: "ComponentContentBlocksDownloadFiles",
                        id: "1",
                        heading: "Whakarewa Annual Reports",
                        description:
                            "Whakarewa annual report for the financial year 2021- 2022",
                        files: [
                            {
                                id: "1",
                                name: "Annual Report 2020",
                                image: { url: "/images/weave.jpg" },
                                file: {
                                    id: "1",
                                    name: "Chorus Fibre Consent Form",
                                    url:
                                        "https://mailchi.mp/b6fa5a334141/te-pakohe-tokerau-2022?e=14b8af76b1",
                                    size: 2680900.21,
                                },
                            },
                            {
                                id: "2",
                                name: "Whakarewa Annual Reports",
                                file: {
                                    id: "1",
                                    name: "Chair Report 2019 - 2020",
                                    url: "http://eepurl.com/hI9AK1",
                                    size: 13809.66,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="3 Tikiake" subtitle="Three no images">
                <DownloadBlock
                    data={{
                        __typename: "ComponentContentBlocksDownloadFiles",
                        id: "1",
                        heading: "Whakarewa Annual Reports",
                        description:
                            "Whakarewa annual report for the financial year 2021- 2022",
                        files: [
                            {
                                id: "1",
                                name: "Annual Report 2020",
                                file: {
                                    id: "1",
                                    name: "Chorus Fibre Consent Form",
                                    url:
                                        "http://www.ngatikuia.iwi.nz/assets/Uploads/Back-to-School-Grant-2020.pdf",
                                    size: 2680900.21,
                                },
                            },
                            {
                                id: "2",
                                file: {
                                    id: "1",
                                    name: "Chair Report  2019 - 2020",
                                    url:
                                        "http://www.ngatikuia.iwi.nz/assets/Uploads/Back-to-School-Grant-2020.pdf",
                                    size: 13809.66,
                                },
                            },
                            {
                                id: "3",
                                file: {
                                    id: "1",
                                    name: "Chair Report 2019 - 2020",
                                    url:
                                        "http://www.ngatikuia.iwi.nz/assets/Uploads/Back-to-School-Grant-2020.pdf",
                                    size: 13809.66,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="3 Tikiake" subtitle="Three with images">
                <DownloadBlock
                    data={{
                        __typename: "ComponentContentBlocksDownloadFiles",
                        id: "1",
                        heading: "Whakarewa Annual Reports",
                        description:
                            "Whakarewa annual report for the financial year 2021- 2022",
                        files: [
                            {
                                id: "1",
                                name: "Annual Report 2020",
                                image: { url: "/images/te-uma-seats.png" },
                                file: {
                                    id: "1",
                                    name: "Te Pakohe tokerau 2022",
                                    url:
                                        "https://mailchi.mp/b6fa5a334141/te-pakohe-tokerau-2022?e=14b8af76b1",
                                    size: 2680900.21,
                                },
                            },
                            {
                                id: "2",
                                name: "Annual Report 2019",
                                image: { url: "/images/interpretation-panels.png" },
                                file: {
                                    id: "1",
                                    name: "Election Notice 2021",
                                    url:
                                        "https://images.test.ngatikuia.iwi.nz/Maranga_Mai_May_2016_c2cb1285e5.pdf",
                                    size: 13809.66,
                                },
                            },
                            {
                                id: "3",
                                name: "Annual Report 2018",
                                image: { url: "/images/te-uma-seats.png" },
                                file: {
                                    id: "1",
                                    name: "Chair Report 2020",
                                    url: "http://eepurl.com/hI9AK1",
                                    size: 13809.66,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection
                title="Kawepūrongo"
                subtitle="News Grid"
                contain={false}>
                <NewsGridBlock
                    data={{
                        __typename: "ComponentContentBlocksNewsGrid",
                        id: "1",
                        title: "Stay up to date",
                        pages: [
                            {
                                title: "Roadshows",
                                slug: "/history",
                                cover_image: {
                                    url: "/images/te-uma-seats.png",
                                },
                            },
                            {
                                title: "Kaumātua Christmas Lunch",
                                slug: "/history",
                                cover_image: {
                                    url: "/images/interpretation-panels.png",
                                },
                            },
                            {
                                title: "Wānanga Pakohe",
                                slug: "/history",
                                cover_image: {
                                    url: "/images/wānanga.jpg",
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="Whakahauhau" subtitle="Call To Action">
                <CallToActionBlock
                    data={{
                        __typename: "ComponentContentBlocksCallToAction",
                        id: "1",
                        callToActionTitle: "Register with Whakarewa",
                        description: "Naumai haere mai",
                        action: {
                            label: "Join Us",
                            link: { slug: "/link" },
                            member_link: { slug: "/link" },
                        },
                        background: { url: "/images/guardians.jpg" },
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection
                title="Wātaka Kūkara"
                subtitle="Google Calendar"
                contain>
                <GoogleCalendar
                    data={{
                        __typename: "ComponentContentBlocksGoogleCalendar",
                        id: "1",
                        calendarId: "b4cfchp0jp9st71vhqm1hjgiio",
                        active: "true",
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection
                title="Kawepūrongo"
                subtitle="News Grid"
                contain={false}>
                <NewsGridBlock
                    data={{
                        __typename: "ComponentContentBlocksNewsGrid",
                        id: "1",
                        title: "Stay up to date",
                        pages: [
                            {
                                title: "Roadshows",
                                slug: "/history",
                                cover_image: {
                                    url: "/images/roadshow.jpg",
                                },
                            },
                            {
                                title: "Kaumātua Christmas Lunch",
                                slug: "/history",
                                cover_image: {
                                    url: "/images/our-place-te-uma.png",
                                },
                            },
                            {
                                title: "Wānanga Pakohe",
                                slug: "/history",
                                cover_image: {
                                    url: "/images/wānanga.jpg",
                                },
                            },
                            {
                                title: "Honey",
                                slug: "/history",
                                cover_image: {
                                    url: "/images/honey.jpg",
                                },
                            },
                            {
                                title: "Whakarewa Day",
                                slug: "/history",
                                cover_image: {
                                    url: "/images/ngāti-kuia-day.jpg",
                                },
                            },
                            {
                                title: "Kānuka Tea",
                                slug: "/history",
                                cover_image: {
                                    url: "/images/kanuka-tea.jpg",
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="Tikiake" subtitle="Download">
                <DownloadBlock
                    data={{
                        __typename: "ComponentContentBlocksDownloadFiles",
                        id: "1",
                        heading: "Whakarewa Annual Reports",
                        description:
                            "Whakarewa annual report for the financial year 2021- 2022 entails the many achievements as well as financial transparency reporting. Iwi ki a ratou. He nui rapea nga korero mo nga rori, mo nga ruritanga hoki, mo te aha atu; otira ko te tino kupu tena.",
                        files: [
                            {
                                id: "1",
                                image: { url: "/images/interpretation-panels.png" },
                                name: "Annual Report 2020",
                                file: {
                                    id: "1",
                                    name: "Chorus Fibre Consent Form",
                                    url:
                                        "http://www.ngatikuia.iwi.nz/assets/Uploads/Back-to-School-Grant-2020.pdf",
                                    size: 2680900.21,
                                },
                            },
                            {
                                id: "2",
                                image: { url: "/images/interpretation-panels.png" },
                                file: {
                                    id: "1",
                                    name: "Chair Report 2019 - 2020",
                                    url:
                                        "http://www.ngatikuia.iwi.nz/assets/Uploads/Back-to-School-Grant-2020.pdf",
                                    size: 13809.66,
                                },
                            },
                            {
                                id: "3",
                                image: { url: "/images/interpretation-panels.png" },
                                name: "CEO Report 2020 - 2021",
                                file: {
                                    id: "1",
                                    name: "Chorus Fibre Consent Form",
                                    url:
                                        "http://www.ngatikuia.iwi.nz/assets/Uploads/Back-to-School-Grant-2020.pdf",
                                    size: 546.08,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="Tiriata" subtitle="YouTube">
                <YoutubePlayerContentBlock
                    data={{
                        __typename: "ComponentContentBlocksYoutubePlayer",
                        id: "1",
                        title: "Video Title",
                        description:
                            "Videos to keep people on your website longer and engage them with your content. People work with people, and video helps people get to know, like and trust you. Videos keep your audience interested, no matter your product or service. Videos are fast and convenient.",
                        youtube_url: "https://youtu.be/7WfFKJmJ7NE",
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="Rārangi Tiro" subtitle="Profile Grid">
                <section>
                    <div className="profile-grid-wrapper || constrain-width">
                        <h3>Tāngata</h3>
                        <div>
                            <p>
                                Throw in a mix of creative, editorial,
                                managerial and technical skills these are
                                without a doubt the plurality of skills of our
                                team that are Whakarewa strength. Where is the
                                happiness officer and the smoothie bar? Not
                                here, here we prefer to rely on a strategy and a
                                thoughtful organization that promote the
                                development of our tāngata.
                            </p>
                        </div>

                        <div className="profile-grid-items">
                            <a
                                className="profile-grid-item has-background"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/benjamin-kaveney-gibb.png")',
                                }}
                                href="#benjamin">
                                <div>
                                    <h4 className="profile-grid-title has-background">
                                        Benjamin Kaveney Gibb
                                    </h4>
                                </div>
                            </a>

                            <a
                                className="profile-grid-item has-background"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/mariah-hori-te-pa.png")',
                                }}
                                href="#mariah">
                                <div>
                                    <h4 className="profile-grid-title has-background">
                                        Mariah Hori Te Pa
                                    </h4>
                                </div>
                            </a>
                            <Link
                                className="profile-grid-item has-background"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/benjamin-kaveney-gibb.png")',
                                }}
                                to="/">
                                <div>
                                    <h4 className="profile-grid-title has-background">
                                        Benjamin
                                    </h4>
                                </div>
                            </Link>
                        </div>

                        <div className="profile-grid-items">
                            <Link
                                className="profile-grid-item has-background"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/mariah-hori-te-pa.png")',
                                }}
                                to="/">
                                <div>
                                    <h4 className="profile-grid-title has-background">
                                        Mariah Hori Te Pa
                                    </h4>
                                </div>
                            </Link>
                            <Link
                                className="profile-grid-item has-background"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/benjamin-kaveney-gibb.png")',
                                }}
                                to="/">
                                <div>
                                    <h4 className="profile-grid-title has-background">
                                        Benjamin
                                    </h4>
                                </div>
                            </Link>
                            <Link
                                className="profile-grid-item has-background"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/mariah-hori-te-pa.png")',
                                }}
                                to="/">
                                <div>
                                    <h4 className="profile-grid-title has-background">
                                        Mariah Hori Te Pa
                                    </h4>
                                </div>
                            </Link>
                        </div>

                        <div className="profile-grid-items">
                            <a
                                className="profile-grid-item has-background"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/benjamin-kaveney-gibb.png")',
                                }}
                                href="#shanell">
                                <div>
                                    <h4 className="profile-grid-title has-background">
                                        Benjamin K-G
                                    </h4>
                                </div>
                            </a>
                            <Link
                                className="profile-grid-item has-background"
                                style={{
                                    backgroundImage:
                                        'url("/images/wānanga.jpg")',
                                }}
                                to="/">
                                <div>
                                    <h4 className="profile-grid-title has-background">
                                        Ariana Banks
                                    </h4>
                                </div>
                            </Link>
                        </div>
                    </div>
                </section>
            </StyleGuideSection>

            <StyleGuideSection
                title="Pikitia Kōtaha"
                subtitle="Profile Pictures">
                <section>
                    <div className="profile-wrapper || constrain-width">
                        <h3>Whakarewa Team</h3>
                        <div className="profile-content-title">
                            <h2 id="benjamin" className="profile-subtitle">
                                Benjamin-Kaveney-Gibb
                            </h2>
                        </div>

                        <div className="profile-columns">
                            <div
                                className="profile-image right bottom"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/benjamin-kaveney-gibb.png")',
                                }}
                            />
                            <div className="profile-columns">
                                <div className="profile-columns-text">
                                    <h2 className="profile-subtitle">
                                        Software
                                    </h2>
                                    <p>
                                        Ekore e tika kia noho he Maori rawakore
                                        ki tenei whenua; ehara tenei i te mea e
                                        ora ai tatou e tika ai ranei ratou. E
                                        mea ana tatou kia noho te Maori i runga
                                        i te rangimarie penei me tatou, a e ki
                                        ana ahau mehemea tera tetahi iwi i
                                        whiwhi nui ki te whenua kua kite ratou
                                        kei to mawehe atu o ratou whenua i a
                                        ratou, e tika ana ano kia pouri ratou.
                                        Ko te utu mo te Waka, Maori i te tau ka
                                        te 10s., he mea utu ki mua. Ka tukuna
                                        atu i te meera ki te tangata e hiahia
                                        ana me ka tukua mai e ia aua moni ki te
                                        Kai Tuhi ki Poneke nei. Kua tonoa te
                                        Paremete e te Kawana kia hui mai i te 2
                                        o nga haora o te awatea o te Taitei, te
                                        15 o Hune, ki te mahi i nga mahi o te
                                        motu. Ta ratou kupu i reira ko te whenua
                                        kia whakawakia kia hokona, kia watea
                                        ratou i nga raruraru e homai ana e etahi
                                        iwi ki a ratou. He nui rapea nga korero
                                        mo nga rori, mo nga ruritanga hoki, mo
                                        te aha atu; otira ko te tino kupu tena,
                                        ko te whakawa, ko te hoko. Nga mihi
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="profile-wrapper || constrain-width">
                        <div className="profile-content-title-right">
                            <h2 id="mariah" className="profile-subtitle">
                                Mariah Hori Te Pa
                            </h2>
                        </div>

                        <div className="profile-columns">
                            <div
                                className="profile-image left bottom"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/mariah-hori-te-pa.png")',
                                }}
                            />
                            <div className="profile-columns-right">
                                <div className="profile-columns-text">
                                    <h2 className="profile-subtitle">
                                        Software
                                    </h2>
                                    <p>
                                        Rebecca is a mokopuna of Martin Mason
                                        and Haromi Walker, and has whakapapa
                                        links to Meihana Kereopa, Hana Whiro,
                                        Ina Hemi, Jimmy Walker, Wi Te Mete and
                                        Tire White. She is a Trustee responsible
                                        for our Te Putea Pou along with Wayne
                                        Hemi, and is a member of our Grants
                                        Komiti. She is also Whakarewa’s
                                        representative on the Nelson/Marlborough
                                        Iwi Health Board and is overseeing our
                                        Hauora initiative He Maunga Pakohe
                                        Rautaki Hauora. Rebecca has worked for
                                        major corporations and government
                                        departments in NZ and London. She has
                                        been responsible for country-wide
                                        strategic planning processes, has broad
                                        commercial and pricing experience and
                                        has brokered international
                                        collaborations. She is also a Chartered
                                        Accountant and member of the Institute
                                        of Directors. Rebecca lives in central
                                        Nelson, is married with two children and
                                        is learning Te Reo at Whakarewa night
                                        classes
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="profile-wrapper || constrain-width">
                        <div className="profile-content-title">
                            <h2 id="mariah">Mariah Hori Te Pa</h2>
                        </div>

                        <div className="profile-columns">
                            <div
                                className="profile-image right bottom"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/mariah-hori-te-pa.png")',
                                }}
                            />
                            <div className="profile-columns">
                                <div className="profile-columns-text">
                                    <h2>Software</h2>
                                    <p>
                                        “I have had a fantastic six years at university
                                        and there is nothing that I would change if I 
                                        were to do it again. My time here has been 
                                        characterised by variety: by trying new things, 
                                        meeting new people and saying ‘yes’ to new 
                                        opportunities and experiences. I have been a 
                                        class representative, student mentor for junior
                                        Māori and Pasifika law students, and a disability
                                        services class note-taker for people in my class
                                        who can’t take their own notes. I have been a 
                                        secretary for the Māori Law Students’ and the 
                                        Pasifika Law Students’ Societies, and Vice-President
                                        of the VUW Feminist Law Society. All of these have
                                        been a result of simply being curious, being 
                                        motivated, and putting my hand up. I love the 
                                        journey I have taken and feel as if I am a better 
                                        and well-rounded person for all of these experiences
                                        and more. If I had any more advice it would be to say 
                                        yes to the opportunities that come your way – even if
                                        they scare you!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </StyleGuideSection>

            <StyleGuideSection title="Hononga" subtitle="Links">
                <LinksBlock
                    data={{
                        __typename: "ComponentContentBlocksLinks",
                        id: "123",
                        title: "Helpful Links",
                        description:
                            "Info and resources specifically for whanau. He nui rapea nga korero mo nga rori, mo nga ruritanga hoki, Latest from Whakarewa. Information on Health. Iwi ki a ratou. Looking after mental health and wellbeing during covid. mo te aha atu; otira ko te tino kupu",
                        links: [
                            {
                                title: "Whakarewa Honey",
                                url:
                                    "https://www.aceaotearoa.org.nz/news-and-resources/news/ng%C4%81ti-kuia-building-wh%C4%81nau-skills",
                            },
                            {
                                title: "Covid Advice for Māori",
                                url:
                                    "https://www.aceaotearoa.org.nz/news-and-resources/news/ng%C4%81ti-kuia-building-wh%C4%81nau-skills",
                            },
                            {
                                title: "Healthline",
                                url:
                                    "https://www.aceaotearoa.org.nz/news-and-resources/news/ng%C4%81ti-kuia-building-wh%C4%81nau-skills",
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection
                title="Kete Whakaahua"
                subtitle="Gallery"
                contain={false}>
                <GalleryBlock
                    data={{
                        __typename: "ComponentContentBlocksImageGallery",
                        id: "1",
                        heading: "Kotahi",
                        galleryId: "123456",
                        description:
                            "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika. I te hokinga mai o ona tuakana ki tatahi, ka kii atu a Maui, “ka taea e au te haramai i to koutou na taha ki te hii ika?” Engari, ko te whakautu o ona tuakana ki a ia ano, “Kao, he rangatahi noa iho koe. Kaore he wahi mau kei te waka nei, na reira me noho tau ki tatahi ke”.",
                        images: [
                            {
                                heading: "Rua",
                                description:
                                    "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika. ratou. He nui rapea nga korero mo nga roriratou. He nui rapea nga korero mo nga roriratou. He nui rapea nga korero mo nga rori",
                                image: {
                                    alternativeText: "",
                                    url: "/images/interpretation-panels.png",
                                    height: 800,
                                    width: 800,
                                },
                            },
                            {
                                heading: "Wha",
                                description:
                                    "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika.",
                                image: {
                                    alternativeText: "",
                                    url: "/images/weave.jpg",
                                    height: 800,
                                    width: 800,
                                },
                            },
                            {
                                heading: "Tahi",
                                description:
                                    "Ko te moemoea a Maui kia haere ngatahi ai ratou Maui kia haere ngatahi tuakana ki te hii ika.",
                                image: {
                                    alternativeText: "",
                                    url: "/images/paddock.png",
                                    height: 800,
                                    width: 800,
                                },
                            },
                            {
                                heading: "Toru",
                                description:
                                    "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika.",
                                image: {
                                    alternativeText: "",
                                    url: "/images/waka.jpg",
                                    height: 800,
                                    width: 800,
                                },
                            },
                            {
                                heading: "Rima",
                                description:
                                    "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika.",
                                image: {
                                    alternativeText: "",
                                    url: "/images/interpretation-panels.png",
                                    height: 800,
                                    width: 800,
                                },
                            },
                            {
                                heading: "Ono",
                                description:
                                    "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika.",
                                image: {
                                    alternativeText: "",
                                    url: "/images/weave-white.jpg",
                                    height: 800,
                                    width: 800,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="Puka" subtitle="Forms" contain>
                <Form className="constrain-width">
                    <div className="form-row">
                        <Field
                            label="Example Tahi"
                            type="text"
                            name="example-tahi"
                        />
                        <Field
                            label="Example Rua"
                            type="text"
                            name="example-rua"
                        />
                        <Field
                            label="Example Toru"
                            type="text"
                            name="example-toru"
                        />
                    </div>
                    <div className="form-row">
                        <Field
                            label="Example Wha"
                            type="text"
                            name="example-wha"
                        />
                    </div>
                    <div className="form-row">
                        <Field type="text" name="example-wha" />
                    </div>
                </Form>
            </StyleGuideSection>

            <StyleGuideSection title="Whakataka" subtitle="Dropdown" contain>
                <Form className="constrain-width">
                    <div className="form-row">
                        <Dropdown
                            label="Title"
                            options={["Mr", "Mrs", "Miss", "Other"]}
                        />
                        <Dropdown
                            label="Education"
                            options={[
                                "Study",
                                "Apprenticeship",
                                "Tertiary",
                                "Degree",
                                "Masters",
                                "Trade",
                                "Other",
                            ]}
                        />
                    </div>
                </Form>
            </StyleGuideSection>

            <StyleGuideSection
                title="Pātene Irirangi"
                subtitle="Radio Buttons"
                contain>
                <div className="constrain-width">
                    <RadioColumn label="Gender" text="Wahine" />
                    <RadioColumn text="Tane" />
                    <RadioColumn text="Ira Weherua-kore" />
                    <div className="radio-row">
                        <Radio text="Mr" />
                        <Radio text="Mrs" />
                        <Radio text="Miss" />
                    </div>
                </div>
            </StyleGuideSection>

            <StyleGuideSection title="Pōuakataki" subtitle="Checkboxes" contain>
                <div className="constrain-width">
                    <p>
                        {" "}
                        I agree that I have read and accepted the terms and
                        conditions{" "}
                    </p>
                    <Checkbox text="Āe" />
                    <Checkbox text="Kaō" />
                </div>
            </StyleGuideSection>

            <StyleGuideSection title="Rā Whānau" subtitle="Birthdate" contain>
                <Form className="constrain-width">
                    <div className="form-row">
                        <DatePicker
                            defaultDate={new Date("01/12/2022")}
                            name="birthdate"
                        />
                    </div>
                </Form>
            </StyleGuideSection>

            <StyleGuideSection
                title="Rapu Wāhi Noho"
                subtitle="Address Finder"
                contain>
                <Form className="constrain-width">
                    <AddressFinder onChange={() => {}} />
                </Form>
            </StyleGuideSection>

            <StyleGuideSection
                title="Papa Waitohu"
                subtitle="Signature Pad"
                contain>
                <Form className="constrain-width">
                    <SignatureCanvas text="Please sign on the signature pad" />
                </Form>
            </StyleGuideSection>

            <StyleGuideSection title="Dynamic" subtitle="Field" contain>
                <Form className="constrain-width">
                    <Whakapapa onChange={() => {}} />
                </Form>
            </StyleGuideSection>
        </div>
    );
};
